import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CarouselCr from "../components/carousel";
import ArticlePreview from "../components/articlePreview"
import Konami from "konami";

const IndexPage = (data) => {
  const news = data.data.news
  const posts = data.data.posts

  const easter_egg = typeof document !== `undefined` ?  new Konami(function() {window.location= "https://crimsonrgames.com/secret/crb5-digital-fly.html"}) : null

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home"
        description="Crimson R Games publishes apps and games developed mostly solo by Aldo Pedro Rangel Montiel(aldoram5). Find games, apps and devlogs."
        keywords={[`crimsonrgames`, `games`, `indie`, `development`, 'aldoram5', 'Aldo Pedro Rangel Montiel', 'indie games', 'apps', 'indie apps']} />
      <Row className="black_background no_scroll_x">
        <Col>
          <CarouselCr></CarouselCr>
        </Col>
      </Row>
      <Container className="text-center">
        <Row className="justify-content-center my-3">
          <Col >
            <h3>Latest News:</h3>
            <hr></hr>
            {news.edges.map(row => (
              <ArticlePreview
                key={row.node.childMarkdownRemark.frontmatter.title}
                title={row.node.childMarkdownRemark.frontmatter.title}
                date={row.node.childMarkdownRemark.frontmatter.date}
                img={row.node.childMarkdownRemark.frontmatter.featuredImage.childImageSharp.fluid.src}
                excerpt={row.node.childMarkdownRemark.excerpt}
                slug={row.node.childMarkdownRemark.fields.slug}
              ></ArticlePreview>
            ))}

            <div className="text-right">

              <Link to="/all-news" className="link-no-style">

                <h5>See all news</h5>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col >
            <h3>Latest Blog posts:</h3>
            <hr></hr>
            {posts.edges.map(row => (
              <ArticlePreview
                key={row.node.childMarkdownRemark.frontmatter.title}
                title={row.node.childMarkdownRemark.frontmatter.title}
                date={row.node.childMarkdownRemark.frontmatter.date}
                excerpt={row.node.childMarkdownRemark.excerpt}
                img={row.node.childMarkdownRemark.frontmatter.featuredImage.childImageSharp.fluid.src}
                slug={row.node.childMarkdownRemark.fields.slug}
              ></ArticlePreview>
            ))}
            <div className="text-right">

              <Link to="/all-posts" className="link-no-style">

                <h5>See all blog posts</h5>
              </Link>
            </div>

          </Col>
        </Row>
      </Container>
      <Row className="justify-content-center whitesmoke_bg ">
        <Col >
          <Container className="text-center">
            <Row className="mt-3 mb-3">
              <div className="col-xs-12 col-md-6"><Link to="/about">About us</Link></div>
              <div className="col-xs-12 col-md-6"><Link to="/about-site">About the site</Link></div>
              <div className="col-xs-12 col-md-6"><a href="mailto:aldo@crimsonrgames.com" target="_top">Contact us</a></div>
              <div className="col-xs-12 col-md-6"><a href="mailto:support@crimsonrgames.com?Subject=I%20need%20help" target="_top">Get support</a></div>
            </Row>
          </Container>
        </Col>
      </Row>
    </Layout>
  )
}


export const query = graphql`
query {
    news: allFile(filter: {relativeDirectory: {eq: "news"}}, sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date}, limit: 5) {
      edges {
        node {
          id
          name
          childMarkdownRemark {
            id
            excerpt(pruneLength: 180, format: MARKDOWN)
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }

    posts: allFile(filter: {relativeDirectory: {eq: "posts"}}, sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date}, limit: 5) {
      edges {
        node {
          id
          name
          childMarkdownRemark {
            id
            excerpt
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
    
  }
`
export default IndexPage
