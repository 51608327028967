import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { Navbar, Nav, Form, FormControl, Button, Carousel } from "react-bootstrap"

const CarouselCr = () => {
    const { allCarouselCsv, allFile } = useStaticQuery(
        graphql`
        {
            allCarouselCsv {
              edges {
                node {
                  id
                  image
                  title
                  url
                  subtitle
                }
              }
            }
            allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
                edges {
                  node {
                    id
                    name
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
          }
        `
    )

    allCarouselCsv.edges.map(row =>{
        allFile.edges.map(img =>{
            console.log(img)
            console.log(row.node.image + " " + img.node.name)
            if (row.node.image == img.node.name){
                row.node.fluid = img.node.childImageSharp.fluid
            }
        })
    })

    return (
        <div>
            <Carousel>
                {allCarouselCsv.edges.map(row => (
                 
                    <Carousel.Item  key={row.node.title}>
                       <Link to={row.node.url}>
                        <Img
                            className="d-block w-100"
                            fluid={row.node.fluid}
                            alt={row.node.title}
                        />
                        <div className="carousel_bg"></div>
                        <Carousel.Caption className="caption_bg">
                            <h3>{row.node.title}</h3>
                            <p>{row.node.subtitle}</p>
                        </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                    
                ))}

            </Carousel>
            
        </div>
    )
}
export default CarouselCr